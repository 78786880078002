import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import Container from 'components/ui/Container';
import * as Styled from './styles';
import { resetPassword } from '../../state/user/user.service';
import Error from './../../assets/images/error.png';
import { useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Danger from "../ui/Alert/Danger";

const ResetPassword = ({ token, locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiResetPasswordPage_en: strapiResetPasswordPage(locale: { eq: "en" }) {
        reset_password_heading
        enter_password_instructions
        password_field_label
        confirm_password_field_label
        reset_password_button_text
        password_reset_success
        back_to_login
      }
      strapiResetPasswordPage_zh: strapiResetPasswordPage(locale: { eq: "zh" }) {
        reset_password_heading
        enter_password_instructions
        password_field_label
        confirm_password_field_label
        reset_password_button_text
        password_reset_success
        back_to_login
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: { eq: "en" }) {
        invalid_password
        password_required
        weak_password
        confirm_password_required
        confirm_password_not_matching
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: { eq: "zh" }) {
        invalid_password
        password_required
        weak_password
        confirm_password_required
        confirm_password_not_matching
        unknown_error
        server_error_500
        server_error_502
        request_error_422
      }
    }
  `);
  const translations = gql[`strapiResetPasswordPage_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const [errors, setErrors] = useState([]);
  const { navigate } = useI18next();
  const [isSuccess, setIsSuccess] = useState(false);

  function checkBtn() {
    const pass = document.getElementById('password').value;
    const confirmPass = document.getElementById('confirmPass').value;
    const btn = document.getElementById('submitBtn');

    if (
      !pass.match(/^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/g) ||
      pass !== confirmPass
    ) {
      btn.disabled = true;
      btn.classList.add('disabledBtn');
      btn.classList.remove('enabledBtn');
    } else {
      btn.disabled = false;
      btn.classList.remove('disabledBtn');
      btn.classList.add('enabledBtn');
    }
  }

  return (
    <Container section>
      <Styled.Wrapper>
        <div className="title">{translations.reset_password_heading}</div>
        <div className="subTitle">{translations.enter_password_instructions}</div>

        <Formik
          initialValues={{ password: '', confirmedPassword: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.password) {
              errors.password = errTranslations.password_required;
            } else if (
              !values.password.match(/^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/g)
            ) {
              errors.password = errTranslations.weak_password;
            }

            if (!values.confirmedPassword) {
              errors.confirmedPassword = errTranslations.confirm_password_required;
            } else if (values.confirmedPassword && values.password && (values.confirmedPassword !== values.password)) {
              errors.confirmedPassword = errTranslations.confirm_password_not_matching;
            }

            return errors;
          }}
          onSubmit={async (values) => {
            setErrors([])
            const resetPasswordResponse = await resetPassword(
              values.password,
              values.confirmedPassword,
              token
            )
              .toPromise()
              .catch((ajax) => {
                switch (ajax.status) {
                  case 500: setErrors([errTranslations.server_error_500]); break;
                  case 502: setErrors([errTranslations.server_error_502]); break;
                  case 400: setErrors([errTranslations.request_error_422]); break;
                  case 401: setErrors([errTranslations.invalid_login_credentials]); break;
                  case 409: setErrors(['Expired Token']); break;
                  case 422: setErrors([errTranslations.request_error_422]); break;
                  default: setErrors([errTranslations.unknown_error]); break;
                }
              });

            if (resetPasswordResponse.statusCode === 200) {
              setIsSuccess(true);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <p className="inputLabel">{translations.password_field_label}</p>
              <Field type="password" name="password">
                {({ field, meta: { touched, error } }) => (
                  <div className="relative">
                    <input
                      className={
                        touched && error ? 'errorMsg bg-redu-light border border-redu-light' : ''
                      }
                      type="password"
                      id="password"
                      {...field}
                      onKeyUp={checkBtn}
                    />
                    <img
                      src={Error}
                      alt="error"
                      className={
                        touched && error
                          ? 'absolute right-2 top-3 block'
                          : 'absolute right-2 top-3 hidden'
                      }
                    />
                  </div>
                )}
              </Field>
              <ErrorMessage
                name="password"
                component="div"
                className="mt-1 text-xs text-redu-mdlight"
              />
              <p className="inputLabel">{translations.confirm_password_field_label}</p>
              <Field type="password" name="confirmedPassword">
                {({ field, meta: { touched, error } }) => (
                  <div className="relative">
                    <input
                      className={
                        touched && error ? 'errorMsg bg-redu-light border border-redu-light' : ''
                      }
                      type="password"
                      id="confirmPass"
                      {...field}
                      onKeyUp={checkBtn}
                    />
                    <img
                      src={Error}
                      alt="error"
                      className={
                        touched && error
                          ? 'absolute right-2 top-3 block'
                          : 'absolute right-2 top-3 hidden'
                      }
                    />
                  </div>
                )}
              </Field>
              <ErrorMessage
                name="confirmedPassword"
                component="div"
                className="mt-1 text-xs text-redu-mdlight"
              />
              {errors.map((err) => <Danger message={err} />)}
              <button type="submit" id="submitBtn" className="disabledBtn" disabled>
                {translations.reset_password_button_text}
              </button>

              {isSuccess && (
                <div>
                  <div
                    className="flex mt-2 items-center bg-blue-500 text-white text-sm font-bold px-4 py-3"
                    role="alert"
                  >
                    <p>{translations.password_reset_success}</p>
                  </div>

                  <Styled.Button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/login/');
                    }}
                    className="bg-gray-200 w-full"
                  >
                    {translations.back_to_login}
                  </Styled.Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Styled.Wrapper>
    </Container>
  );
};

ResetPassword.propTypes = {
  token: PropTypes.any,
  locale: PropTypes.string,
};

ResetPassword.defaultProps = {
  locale: 'en',
};

export default ResetPassword;
